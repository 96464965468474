<template>
    <b-container>
        <div>
            <div style="position: relative" v-if="!planCheck && getted">
                <!-- <PlanSelectionVue /> -->
                <div class="d-flex flex-1 blured-div">
                    <div class="wrapper">
                        <div class="text-center">
                            <h1 class="d-sm-none d-block">
                                Unlock Keyword Search with an Upgrade
                            </h1>
                            <h1 style="font-size: 3rem" class="d-none d-sm-block">
                                Unlock Keyword Search with an Upgrade
                            </h1>
                            <h4>
                                When you upgrade to Pro, you will be able to access keyword
                                search, niches, and more tools from AppLead.
                            </h4>
                        </div>
                        <b-button style="font-size: 2rem" variant="outline-success" to="/pages/plan-selection">
                            Upgrade Now
                        </b-button>
                    </div>
                </div>
                <div class="container" style="filter: blur(1px)">
                    <validation-observer>
                        <b-form id="searchForTheKeyword">
                            <b-card class="d-flex mb-0">
                                <b-row class="border rounded p-2 justify-content-center align-items-center mx-0">
                                    <b-col cols="12" md="3">
                                        <h3 class="mb-2 mb-md-0">Keyword Search</h3>
                                    </b-col>
                                    <b-col cols="12" md="7">
                                        <b-container>
                                            <b-row>
                                                <b-col cols="12">
                                                    <b-form-group class="mb-0">
                                                        <input type="text" class="form-control"
                                                            placeholder="Enter The Keyword." value="" />
                                                        <small class="text-danger keyword-name-error"></small>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </b-col>
                                    <b-col cols="12" md="2">
                                        <b-container>
                                            <b-row>
                                                <b-col cols="12" class="d-flex justify-content-center mt-2 mt-md-0">
                                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                        variant="outline-info" class="mr-1 circle" type="submit"
                                                        v-b-tooltip.hover title="Search">
                                                        <feather-icon icon="SearchIcon" size="14" />
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-form>
                    </validation-observer>
                    <div class="mt-1">
                        <b-card>
                            <b-table striped hover responsive :items="itemsDefault" :fields="fields">
                                <template #cell(DifficultyScore)="data">
                                    <span class="mr-1">
                                        {{ data.item.DifficultyScore }} / 10
                                    </span>
                                    <img class="img-score" v-if="
                                        data.item.DifficultyScore >= 0 &&
                                        data.item.DifficultyScore < 2.5
                                    " :src="prgressBarCircle1" />
                                    <img class="img-score" v-if="
                                        data.item.DifficultyScore >= 2.5 &&
                                        data.item.DifficultyScore < 5
                                    " :src="prgressBarCircle2" />
                                    <img class="img-score" v-if="
                                        data.item.DifficultyScore >= 5 &&
                                        data.item.DifficultyScore < 7.5
                                    " :src="prgressBarCircle3" />
                                    <img class="img-score" v-if="
                                        data.item.DifficultyScore >= 7.5 &&
                                        data.item.DifficultyScore < 10
                                    " :src="prgressBarCircle4" />
                                </template>
                                <template #cell(trafficScore)="data">
                                    <span class="mr-1"> {{ data.item.trafficScore }} / 10 </span>
                                    <img class="img-score" v-if="
                                        data.item.trafficScore >= 0 &&
                                        data.item.trafficScore < 2.5
                                    " :src="prgressBarCircle1_1" />
                                    <img class="img-score" v-if="
                                        data.item.trafficScore >= 2.5 &&
                                        data.item.trafficScore < 5
                                    " :src="prgressBarCircle2_1" />
                                    <img class="img-score" v-if="
                                        data.item.trafficScore >= 5 &&
                                        data.item.trafficScore < 7.5
                                    " :src="prgressBarCircle3_1" />
                                    <img class="img-score" v-if="
                                        data.item.trafficScore >= 7.5 &&
                                        data.item.trafficScore < 10
                                    " :src="prgressBarCircle4_1" />
                                </template>

                                <template #cell(avg_search)="data">
                                    <span v-if="
                                        data.item.trafficScore >= 0 &&
                                        data.item.trafficScore < 2.5
                                    ">10 - 100</span>
                                    <span v-if="
                                        data.item.trafficScore >= 2.5 &&
                                        data.item.trafficScore < 5
                                    ">100 - 1K</span>
                                    <span v-if="
                                        data.item.trafficScore >= 5 &&
                                        data.item.trafficScore < 7.5
                                    ">1K - 10K</span>
                                    <span v-if="
                                        data.item.trafficScore >= 7.5 &&
                                        data.item.trafficScore < 10
                                    ">10K - 100K</span>
                                </template>
                            </b-table>
                            <b-skeleton-table v-if="isloading" :rows="10" :columns="4"
                                :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
                        </b-card>
                    </div>
                </div>
            </div>
            <div v-if="planCheck && getted" style="overflow: hidden">
                <b-modal id="modal-session-app" ok-only ok-variant="warning" ok-title="Login"
                    modal-class="modal-session-app" centered title="Expired !" @ok="gotologin()">
                    <b-card-text>Session Expired</b-card-text>
                </b-modal>
                <b-modal id="modal-save-collection" modal-class="modal-save-collection" centered hide-footer
                    title="Create Collection" ref="refCreateCollectionModal">
                    <div class="d-flex">
                        <div class="d-flex flex-column w-100">
                            <b-form-input v-model="CollectionName" placeholder="Collection name" />
                            <small ref="createCollectionError" class="text-danger"></small>
                        </div>
                        <b-button class="ml-2" variant="success" @click="createCollection"
                            style="padding: 5px 10px;height: 37px;">
                            <feather-icon icon="SaveIcon" size="18" />
                        </b-button>

                    </div>
                </b-modal>
                <b-modal id="modal-collection" modal-class="modal-collection " hide-footer title="Add To Collection"
                    ref="refNewCollectionModal">
                    <div class="new-collection position-relative h-100">
                        <div class="d-flex align-items-center justify-content-between m-2">
                            <h3>Add new collection</h3>
                            <b-button class="p-0" variant="success">
                                <feather-icon icon="PlusIcon" @click="popSaveModal" id="newCol" size="30" />
                            </b-button>
                        </div>
                        <div class="body mt-2 collection-content scrollable_container">
                            <div v-for="(item) in CollectionItems" :key="item.id"
                                class="d-flex align-items-center justify-content-between m-2">
                                <div><feather-icon icon="FolderIcon" size="20" /></div>
                                <div>{{ item.name }}</div>
                                <div>
                                    <input type="radio" class="radio" v-model="selected" :value="item.id"
                                        name="radio-collection" />
                                </div>
                            </div>
                        </div>
                        <div class="div-btn pt-2" v-if="CollectionItems.length > 0">
                            <div class="modal-footer w-100 d-flex align-items-center justify-content-center">
                                <b-button variant="success" class="mb-0" style="width: 120px"
                                    @click="addKeywordsToCollection"><feather-icon icon="SaveIcon" id="newCol"
                                        size="18" />
                                    save</b-button>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <div class="container">
                    <validation-observer>
                        <b-form id="searchForTheKeyword" @submit.prevent="getSuggests()">
                            <b-card class="mb-0">
                                <div class="border rounded p-1">
                                    <b-row cols="12">
                                        <b-col sm="12" md="3" class="d-flex align-items-center justify-content-between">
                                            <svg style="width: 50px" aria-hidden="true" viewBox="0 0 40 40"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill="none" d="M0,0h40v40H0V0z"></path>
                                                <g>
                                                    <path
                                                        d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z"
                                                        fill="#EA4335"></path>
                                                    <path
                                                        d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z"
                                                        fill="#FBBC04"></path>
                                                    <path
                                                        d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z"
                                                        fill="#4285F4"></path>
                                                    <path
                                                        d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z"
                                                        fill="#34A853"></path>
                                                </g>
                                            </svg>
                                            <h3 class="function-title">Keyword Search</h3>
                                        </b-col>
                                        <b-col sm="12" md="6">
                                            <b-form-group class="mb-0">
                                                <input type="text" class="form-control w-100"
                                                    placeholder="Enter The Keyword." v-model="keywordName"
                                                    v-on:input="handleKeywordSearch" />
                                                <small ref="searchError" class="text-danger"></small>
                                                <small class="text-danger keyword-name-error"></small>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="12" md="3">
                                            <b-container>
                                                <b-row>
                                                    <b-col cols="12" class="d-flex justify-content-center mt-2 mt-md-0">
                                                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                            variant="outline-success" class="circle w-100" type="submit"
                                                            v-b-tooltip.hover title="Search">
                                                            <feather-icon icon="SearchIcon" size="14" />
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </b-container>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-card>
                        </b-form>
                    </validation-observer>
                    <div class="mt-1">
                        <b-card v-if="isloading || items.length > 0">
                            <div class="d-flex justify-content-end mb-1">
                                <b-button @click="popCollectionModal"
                                    :class="`d-flex align-items-center ${!keywordsCheckboxes.length > 0 && 'cursor_none'}`"
                                    variant="success" :disabled="keywordsCheckboxes.length > 0 ? false : true">
                                    <feather-icon class="mr-1" icon="PlusCircleIcon" size="20" />
                                    <span style="margin-top: 3px">Add To Collection</span>
                                </b-button>
                            </div>
                            <b-table v-if="!isloading && items.length > 0" striped hover responsive :per-page="perPage"
                                :current-page="currentPage" :items="items" :fields="fields" :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
                                :filter-included-fields="filterOn" @filtered="onFiltered">
                                <!-- <template #head(keywords_Checkbox)>
                  <b-form-checkbox v-model="checkAll" @change="handleCheckAllChange"></b-form-checkbox>
                </template> -->
                                <!-- <template #head(keywords_Checkbox)>
                  <b-form-checkbox v-model="checkAll"></b-form-checkbox>
                </template> -->
                                <template #head(keywords_Checkbox)>

                                </template>

                                <template #cell(DifficultyScore)="data">
                                    <span class="mr-1">
                                        {{ data.item.DifficultyScore.toFixed(2) }} / 10
                                    </span>
                                    <img class="img-score" v-if="
                                        data.item.DifficultyScore >= 0 &&
                                        data.item.DifficultyScore < 2.5
                                    " :src="prgressBarCircle1" />
                                    <img class="img-score" v-if="
                                        data.item.DifficultyScore >= 2.5 &&
                                        data.item.DifficultyScore < 5
                                    " :src="prgressBarCircle2" />
                                    <img class="img-score" v-if="
                                        data.item.DifficultyScore >= 5 &&
                                        data.item.DifficultyScore < 7.5
                                    " :src="prgressBarCircle3" />
                                    <img class="img-score" v-if="
                                        data.item.DifficultyScore >= 7.5 &&
                                        data.item.DifficultyScore < 10
                                    " :src="prgressBarCircle4" />
                                </template>
                                <template #cell(trafficScore)="data">
                                    <span class="mr-1">
                                        {{ data.item.trafficScore.toFixed(2) }} / 10
                                    </span>
                                    <img class="img-score" v-if="
                                        data.item.trafficScore >= 0 &&
                                        data.item.trafficScore < 2.5
                                    " :src="prgressBarCircle1_1" />
                                    <img class="img-score" v-if="
                                        data.item.trafficScore >= 2.5 &&
                                        data.item.trafficScore < 5
                                    " :src="prgressBarCircle2_1" />
                                    <img class="img-score" v-if="
                                        data.item.trafficScore >= 5 &&
                                        data.item.trafficScore < 7.5
                                    " :src="prgressBarCircle3_1" />
                                    <img class="img-score" v-if="
                                        data.item.trafficScore >= 7.5 &&
                                        data.item.trafficScore < 10
                                    " :src="prgressBarCircle4_1" />
                                </template>
                                <template #cell(avg_search)="data">
                                    <span v-if="
                                        data.item.trafficScore >= 0 &&
                                        data.item.trafficScore < 2.5
                                    ">10 - 100</span>
                                    <span v-if="
                                        data.item.trafficScore >= 2.5 &&
                                        data.item.trafficScore < 5
                                    ">100 - 1K</span>
                                    <span v-if="
                                        data.item.trafficScore >= 5 &&
                                        data.item.trafficScore < 7.5
                                    ">1K - 10K</span>
                                    <span v-if="
                                        data.item.trafficScore >= 7.5 &&
                                        data.item.trafficScore < 10
                                    ">10K - 100K</span>
                                </template>
                                <template #cell(keywords_Checkbox)="data">
                                    <span>
                                        <b-form-checkbox @change="handleCheckboxChange(data.item.title)"
                                            :name="data.item.title" :value="data.item.title"
                                            :disabled="keywordName.trim() ? false : true"
                                            v-model="checkboxStatus[data.item.title]" />
                                    </span>
                                </template>
                            </b-table>
                            <div v-if="isloading" class="skel-dev">
                                <b-skeleton-table :rows="10" :columns="4"
                                    :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
                                <div class="message">
                                    <h1 class="d-sm-none d-block text-center" style="font-size: 19px !important">
                                        We're searching for your keywords now.
                                    </h1>
                                    <h1 class="d-none d-sm-block">
                                        We're searching for your keywords now.
                                    </h1>
                                    <p class="d-sm-none d-block text-center" style="font-size: 16px !important">
                                        This may take up to 40 seconds
                                    </p>
                                    <p class="d-none d-sm-block">
                                        This may take up to 40 seconds
                                    </p>
                                </div>
                            </div>
                        </b-card>
                    </div>
                </div>
            </div>
            <div v-if="!getted">
                <LoaderVue />
            </div>
        </div>
    </b-container>
</template>

<script>
import {
    BRow,
    BCol,
    BModal,
    BCard,
    BFormSelect,
    BTable,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BFormFile,
    BImg,
    BForm,
    BInputGroupAppend,
    BCardText,
    BButtonGroup,
    VBTooltip,
    BPagination,
    BButton,
    VBHover,
    BContainer,
    BFormInvalidFeedback,
    BAspect,
    BSpinner,
    BSkeletonTable,
    BFormCheckbox,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import * as R from "ramda";
import { estimatedFinalScore } from "@/helper/traffic";
import prgressBarCircle1 from "@/assets/images/25.svg";
import prgressBarCircle2 from "@/assets/images/50.svg";
import prgressBarCircle3 from "@/assets/images/75.svg";
import prgressBarCircle4 from "@/assets/images/100.svg";
import prgressBarCircle1_1 from "@/assets/images/25-1.svg";
import prgressBarCircle2_1 from "@/assets/images/50-1.svg";
import prgressBarCircle3_1 from "@/assets/images/75-1.svg";
import prgressBarCircle4_1 from "@/assets/images/100-1.svg";
import { connectApi } from "@/helper/connectApi";
import { deleteAccessTokenCookie } from "../../helper/helpers";
import LoaderVue from "@/components/Loader/Loader.vue";
import PlanSelectionVue from "../subscription/PlanSelection.vue";
export default {
    components: {
        PlanSelectionVue,
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BFormTextarea,
        BCard,
        BContainer,
        BFormInput,
        BFormGroup,
        BFormFile,
        BImg,
        BFormSelect,
        BInputGroupAppend,
        BForm,
        BTable,
        BCardText,
        BInputGroup,
        BModal,
        BButtonGroup,
        VBTooltip,
        BPagination,
        BButton,
        BAspect,
        BFormInvalidFeedback,
        BSpinner,
        BSkeletonTable,
        LoaderVue,
        BFormCheckbox,
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-hover": VBHover,
        Ripple,
    },
    data() {
        return {
            prgressBarCircle1,
            prgressBarCircle2,
            prgressBarCircle3,
            prgressBarCircle4,
            prgressBarCircle1_1,
            prgressBarCircle2_1,
            prgressBarCircle3_1,
            prgressBarCircle4_1,
            titleScore: null,
            competitorScore: null,
            installsScore: null,
            ratingsScore: null,
            ageScore: null,
            keyword: null,
            totalScore: null,
            keyword: null,
            keywordName: "",
            keywordsFile: null,
            perPage: 300,
            pageOptions: [10, 25, 100],
            totalRows: 1,
            currentPage: 1,
            sortBy: "desc",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            items: [],
            itemsDefault: [
                {
                    index: 1,
                    title: "skibidi toilet",
                    DifficultyScore: 5.7,
                    trafficScore: 8.98,
                },
                {
                    index: 2,
                    title: "skibidi toilet game horror",
                    DifficultyScore: 5.38,
                    trafficScore: 8.39,
                },
                {
                    index: 3,
                    title: "skibidi toilet simulator",
                    DifficultyScore: 5.77,
                    trafficScore: 8.29,
                },
                {
                    index: 4,
                    title: "skibidi toilet game vs cameraman",
                    DifficultyScore: 5.64,
                    trafficScore: 4.45,
                },
                {
                    index: 5,
                    title: "skibidi toilet war",
                    DifficultyScore: 5.93,
                    trafficScore: 8.31,
                },
                {
                    index: 6,
                    title: "skibidi toilet game horror offline",
                    DifficultyScore: 5.68,
                    trafficScore: 4.45,
                },
                {
                    index: 7,
                    title: "skibidi toilet game horror survival",
                    DifficultyScore: 3.95,
                    trafficScore: 3.85,
                },
                {
                    index: 8,
                    title: "skibidi toilet game horror real 2023",
                    DifficultyScore: 5.66,
                    trafficScore: 4.45,
                },
                {
                    index: 9,
                    title: "skibidi toilet game horror 3d offline",
                    DifficultyScore: 5.71,
                    trafficScore: 4.45,
                },
                {
                    index: 10,
                    title: "skibidi toilet simulator offline game",
                    DifficultyScore: 5.42,
                    trafficScore: 4.25,
                },
                {
                    index: 11,
                    title: "skibidi toilet simulator roblox",
                    DifficultyScore: 5.87,
                    trafficScore: 4.45,
                },
                {
                    index: 12,
                    title: "skibidi toilet simulator vs cameraman",
                    DifficultyScore: 5.68,
                    trafficScore: 4.45,
                },
                {
                    index: 13,
                    title: "skibidi toilet simulator rope",
                    DifficultyScore: 5.58,
                    trafficScore: 4.92,
                },
                {
                    index: 14,
                    title: "skibidi toilet game vs cameraman offline",
                    DifficultyScore: 5.79,
                    trafficScore: 4.45,
                },
                {
                    index: 15,
                    title: "skibidi toilet game vs cameraman fight",
                    DifficultyScore: 5.58,
                    trafficScore: 4.45,
                },
                {
                    index: 16,
                    title: "skibidi toilet game vs cameraman real game",
                    DifficultyScore: 5.77,
                    trafficScore: 4.45,
                },
                {
                    index: 17,
                    title: "skibidi toilet game vs cameraman melon",
                    DifficultyScore: 5.74,
                    trafficScore: 4.45,
                },
            ],
            filterOn: [],
            fields: [
                {
                    key: "index",
                    label: "#",
                },
                {
                    key: "title",
                    label: "Keyword",
                },
                {
                    key: "trafficScore",
                    label: "Traffic Score",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "DifficultyScore",
                    label: "Difficulty Score",
                    sortable: true,
                    class: "text-center",
                },
                {
                    key: "avg_search",
                    label: "average search",
                    sortable: true,
                },
                {
                    key: "keywords_Checkbox",
                    label: "",
                    sortable: false,
                },
            ],
            firstResult: [],
            lastResult: [],
            isloading: false,
            backupApps: [],
            mainKeywords: [],
            Keywords: [],
            keyApps: [],
            trafficScore: [],
            trafficScore1: [],
            getted: false,
            planCheck: null,
            arabicChar: /[\u0600-\u06FF]/,
            keywordsCheckboxes: [],
            checkAll: false,
            CollectionName: "",
            CollectionItems: [],
            selected: 0,
            checkboxStatus: {},
        };
    },
    mounted() {
        this.checkUserPlan();
        this.getCollections();
    },
    computed: {
        reorderedData() {
            return this.CollectionItems;
        },
    },
    methods: {
        checkUserPlan() {
            axios.post("/checkUserSubscription")
                .then(({ data }) => {
                    this.getted = true;
                    this.planCheck = true;
                })
                .catch((err) => {
                    this.planCheck = false;
                    this.getted = true;
                });
        },
        handleKeywordSearch() {
            if (!this.keywordName.trim()) {
                this.items = [];
                this.uncheckAllCheckboxes()
                this.checkboxStatus = {}
                this.keywordsCheckboxes = []
                this.$refs.searchError.textContent = "The Search Field Is Required !";
            }
        },
        uncheckAllCheckboxes() {
            for (const key in this.checkboxStatus) {
                if (Object.hasOwnProperty.call(this.checkboxStatus, key)) {
                    this.checkboxStatus[key] = false;
                }
            }
        },
        handleCheckboxChange(data) {
            const index = this.keywordsCheckboxes.indexOf(data);

            if (index !== -1) {
                this.keywordsCheckboxes.splice(index, 1);
            } else {
                this.keywordsCheckboxes.push(data);
            }
        },
        popSaveModal() {
            this.$root.$emit("bv::show::modal", "modal-save-collection");
        },
        popCollectionModal() {
            this.getCollections();
            this.$root.$emit("bv::show::modal", "modal-collection");
        },
        reorderCollections() {
            this.CollectionItems.sort((prev, next) => {
                if (prev.name === "Default") {
                    return -1;
                } else if (next.name === "Default") {
                    return 1;
                } else {
                    return 0;
                }
            });
        },
        getCollections() {
            axios.get("/get-collections").then(({ data }) => {
                this.CollectionItems = data.data;
                if (!this.CollectionItems.find((x) => x.name === "Default")) {
                    this.CollectionItems.unshift({ id: 0, name: "Default" });
                } else {
                    this.reorderCollections();
                    this.selected = this.CollectionItems.find(
                        (x) => x.name === "Default"
                    ).id;
                }
            });
        },
        addKeywordsToCollection() {
            const selectedColl = this.CollectionItems.find((elem) => elem.id === this.selected)
            axios.post("/keywords-add", {
                name: this.selected !== selectedColl.id ? "Default" : selectedColl.name,
                SearchTitle: this.keywordName.toLowerCase(),
                keywords: JSON.stringify(this.keywordsCheckboxes),
            })
                .then(({ data }) => {
                    if (data.status === "success") {
                        this.$root.$emit("bv::hide::modal", "modal-collection");
                        this.$root.$emit("bv::hide::modal", "modal-save-collection");
                        this.getCollections();
                        this.showToast("success", "CheckIcon", "Success !", data.message);
                    } else {
                        for (const field in data.message) {
                            data.message[field].forEach(msg => {
                                this.showToast(
                                    "warning",
                                    "AlertTriangleIcon",
                                    "Warning !",
                                    msg
                                );
                            })
                        }
                    }
                });
        },
        createCollection() {
            if (this.CollectionName.trim()) {
                this.$refs.createCollectionError.textContent = "";

                axios.post("/keyword-add-collection", {
                    name: this.CollectionName,
                    SearchTitle: this.keywordName.toLowerCase(),
                    keywords: JSON.stringify(this.keywordsCheckboxes),
                })
                    .then(({ data }) => {
                        if (data.status === "success") {
                            this.$root.$emit("bv::hide::modal", "modal-collection");
                            this.$root.$emit("bv::hide::modal", "modal-save-collection");
                            this.getCollections();
                            this.showToast("success", "CheckIcon", "Success !", data.message);
                        } else {
                            for (const field in data.message) {
                                data.message[field].forEach(msg => {
                                    this.showToast(
                                        "warning",
                                        "AlertTriangleIcon",
                                        "Warning !",
                                        msg
                                    );
                                })
                            }
                        }
                    });
            } else {
                this.$refs.createCollectionError.textContent = "The collection name Field Is Required !";
            }
        },
        async getSuggests(recursionCount = 0) {
            if (this.keywordName.trim()) {
                this.$refs.searchError.textContent = "";
                var lang = "latin";
                if (this.keywordName.match(this.arabicChar)) {
                    lang = "arabic";
                }

                await axios.post("https://sc1.clicklab.app/api/keyword/check", {
                    main: this.keywordName.toLowerCase(),
                    from: "keyword_search",
                    lang,
                })
                    .then(async ({ data }) => {
                        if (data.exist) {
                            this.isloading = true;
                            setTimeout(() => {
                                this.isloading = false;
                                this.items = data.items.result;
                                this.checkboxStatus = Object.fromEntries(this.items.map(item => [item.title, false]));
                            }, 1000);
                            return;
                        } else {
                            const noDataInMongo = async () => {
                                if (this.keywordName) {
                                    var searchKeyword = "";
                                    this.isloading = true;
                                    let count =
                                        this.mainKeywords.length === 0 ? 1 : this.mainKeywords.length;
                                    if (recursionCount >= count) {
                                        this.Keywords.forEach((app) => {
                                            this.getApps(app);
                                        });
                                        return;
                                    }

                                    // Update the keywordName with the new keyword
                                    if (this.mainKeywords.length > 0) {
                                        searchKeyword = this.mainKeywords[recursionCount - 1].term;
                                    } else {
                                        searchKeyword = this.keywordName;
                                    }

                                    try {
                                        const { data } = await connectApi(
                                            `https://scx.clicklab.app/api/apps/?suggest=${searchKeyword}`
                                        );
                                        const { status, results } = data;

                                        if (status === "Unauthorized") {
                                            deleteAccessTokenCookie();
                                            this.$root.$emit("bv::show::modal", "modal-session-app");
                                            return;
                                        }
                                        if (this.mainKeywords.length === 0) {
                                            this.mainKeywords = [...results];
                                            this.mainKeywords.shift();
                                        }
                                        await this.Keywords.push(...results);
                                        await this.getSuggests(recursionCount + 1);
                                    } catch (error) {
                                        console.error(error);
                                    }
                                } else {
                                    this.showToast(
                                        "danger",
                                        "AlertTriangleIcon",
                                        "Keyword !",
                                        "Enter Keyword To search !"
                                    );
                                }
                            };
                            await noDataInMongo();
                        }
                    });
            } else {
                this.$refs.searchError.textContent = "The Search Field Is Required!";
            }
        },
        async getApps(app) {
            try {
                if (!this.firstResult.find((item) => item === app.term)) {
                    this.firstResult.push(app.term);

                    const { data } = await connectApi(
                        `https://scx.clicklab.app/api/apps/?q=${app.term}`
                    );

                    if (data && data.results) {
                        const apps = [...data.results];
                        const newApps = [];

                        for (const ap of apps) {
                            if (ap && ap.appId) {  // Ensure ap and appId are valid
                                const exist = this.backupApps.find(
                                    (item) => item && item.appId === ap.appId  // Ensure item and item.appId are valid
                                );

                                if (!exist) {
                                    const { data: appDetails } = await connectApi(
                                        `https://scx.clicklab.app/api/apps/${ap.appId}/?fullDetail=true`
                                    );
                                    if (appDetails) {
                                        newApps.push(appDetails);
                                        this.backupApps.push(appDetails);
                                    }
                                } else {
                                    newApps.push(exist);
                                }
                            } else {
                                console.error("Invalid app data:", ap);  // Log invalid app data
                            }
                        }

                        const test = {
                            keyword: app.term,
                            apps: newApps,
                        };

                        this.keyApps.push(test);

                        await Promise.all([
                            this.getTtleMatches(app.term, newApps),
                            this.getInstallsScore(newApps),
                            this.getRating(newApps),
                            this.getAge(newApps),
                        ]);

                        this.getScore(app.term);
                    } else {
                        console.error("Invalid response from API for term:", app.term);
                    }
                }
            } catch (error) {
                console.log("error", error);
                // Handle any errors that may occur during the requests.
                this.firstResult = this.firstResult.filter((item) => item !== app.term);
            }
        },
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    text,
                    variant,
                },
            });
        },
        gotologin() {
            this.$router.push({ name: "auth-login" });
        },

        /* ----------------------------------- */
        getMatchType(keyword, title) {
            keyword = keyword.toLowerCase();
            title = title.toLowerCase();

            if (title.includes(keyword)) {
                return "exact";
            }
            const matches = keyword.split(" ").map((word) => title.includes(word));
            if (R.all(R.identity, matches)) {
                return "broad";
            }
            if (R.any(R.identity, matches)) {
                return "partial";
            }
            return "none";
        },
        getTtleMatches(keyword, apps) {
            const matches = R.pluck("title", apps).map((app) =>
                this.getMatchType(keyword, app)
            );
            const counts = {
                exact: R.filter(R.equals("exact"), matches).length,
                broad: R.filter(R.equals("broad"), matches).length,
                partial: R.filter(R.equals("partial"), matches).length,
                none: R.filter(R.equals("none"), matches).length,
            };

            const score =
                (10 * counts.exact + 5 * counts.broad + 2.5 * counts.partial) /
                apps.length;
            this.titleScore = R.assoc("score", score, counts);
        },
        round(val) {
            return Math.round(val * 100) / 100;
        },
        score(min, max, value) {
            value = Math.min(max, value);
            value = Math.max(min, value);
            return this.round(1 + (9 * (value - min)) / (max - min));
        },
        zScore(max, value) {
            return this.score(0, max, value);
        },
        getInstallsScore(apps) {
            const avg = R.sum(R.pluck("minInstalls", apps)) / apps.length;
            const max = 1000000;
            const score = this.zScore(max, avg);
            this.installsScore = { avg: avg, score: score };
        },
        getRating(apps) {
            const avg = R.sum(apps.map((app) => app.score || 0)) / apps.length;
            this.ratingsScore = {
                avg: avg,
                score: avg * 2,
            };
        },

        iScore(min, max, value) {
            value = Math.min(max, value);
            value = Math.max(min, value);
            return this.round(1 + (9 * (max - value)) / (max - min));
        },

        // inverted, zero based score
        izScore(max, value) {
            return this.iScore(0, max, value);
        },
        getDaysSince(date) {
            if (typeof date === "string") {
                date = Date.parse(date);
            } else {
                date = date / 1000;
            }
            return Math.floor((Date.now() - date) / 86400000);
        },
        /*
         * Score the average time since last update among the top apps.
         */
        getAge(apps) {
            const updated = R.pluck("updated", apps).map(this.getDaysSince);
            const avg = R.sum(updated) / apps.length;
            const max = 500;
            const score = this.izScore(max, avg);

            this.ageScore = {
                avg: avg,
                score,
            };
        },

        aggregate(weights, values) {
            const max = 10 * R.sum(weights);
            const min = 1 * R.sum(weights);
            const sum = R.sum(R.zipWith(R.multiply, weights, values));
            return this.score(min, max, sum);
        },
        getScore(app) {
            const TITLE_W = 4;
            // const COMPETITOR_W = 3;
            const INSTALLS_W = 5;
            const RATING_W = 2;
            const AGE_W = 1;
            this.totalScore = this.aggregate(
                [TITLE_W, INSTALLS_W, RATING_W, AGE_W],
                [
                    this.titleScore.score,
                    this.installsScore.score,
                    this.ratingsScore.score,
                    this.ageScore.score,
                ]
            );
            this.lastResult.push({
                title: app,
                DifficultyScore: this.totalScore,
            });
            this.items = [];
            if (this.firstResult.length === this.lastResult.length) {
                // Create a map for faster lookup
                const map = new Map();
                for (const item of this.lastResult) {
                    map.set(item.title, item.DifficultyScore);
                }
                this.items = this.firstResult.map((item, index) => ({
                    index: index + 1,
                    title: item,
                    DifficultyScore: map.get(item) || 0, // If there's no match, default to 0
                }));
                (async () => {
                    // const scorePromises = this.keyApps.map(async (keyApp) => {
                    //   const score = await finalScore(keyApp.keyword, keyApp.apps);
                    //   return { keyword: keyApp.keyword, score: score };
                    // });

                    const scorePromises1 = this.keyApps.map(async (keyApp) => {
                        const score1 = await estimatedFinalScore(
                            keyApp.keyword,
                            keyApp.apps,
                            this.server
                        );
                        return { keyword: keyApp.keyword, score: score1 };
                    });

                    // const [scores, score1] = await Promise.all([Promise.all(scorePromises), Promise.all(scorePromises1)]);
                    const [score1] = await Promise.all([Promise.all(scorePromises1)]);

                    // this.trafficScore.push(...scores);
                    this.trafficScore1.push(...score1);

                    // for (let i = 0; i < this.items.length; i++) {
                    //   this.items[i].trafficScore = this.trafficScore[i].score.score;
                    //   this.items[i].Length = this.trafficScore[i].score.length;
                    // }

                    const titleToIndexMap = {};
                    this.items.forEach((item, index) => {
                        titleToIndexMap[item.title] = index;
                    });

                    // Sort this.trafficScore1 based on the order in this.items
                    this.trafficScore1.sort((a, b) => {
                        const titleA = a.score.keyword;
                        const titleB = b.score.keyword;
                        return titleToIndexMap[titleA] - titleToIndexMap[titleB];
                    });

                    for (let i = 0; i < this.items.length; i++) {
                        if (this.items[i].title === this.trafficScore1[i].score.keyword) {
                            this.items[i].trafficScore = this.trafficScore1[i].score.score;
                        }
                        // this.items[i].LengthEstimated = this.trafficScore1[i].score.length;
                    }
                    this.isloading = false;
                    var lang = "latin";
                    if (this.keywordName.match(this.arabicChar)) {
                        lang = "arabic";
                    }
                    const formData = {
                        main: this.keywordName.toLowerCase(),
                        result: this.items,
                        from: "keyword_search",
                        lang,
                    };

                    try {
                        const response = await axios.post(
                            "https://sc0.clicklab.app/api/store-keyword-score",
                            formData
                        );
                    } catch (error) {
                        console.error("Error while sending the POST request:", error);
                    }
                })();

                this.firstResult = [];
                this.lastResult = [];
                this.Keywords = [];
                this.backupApps = [];
                this.mainKeywords = [];
                this.keyApps = [];
                this.trafficScore1 = [];
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
    },
};
</script>

<style>
.div-btn {
    position: absolute;
    bottom: 70px;
    width: 100%;
}

.radio {
    accent-color: #33d47c;
}

.cursor_none {
    cursor: not-allowed !important;
}

.scrollable_container {
    max-height: 250px !important;
    overflow-y: auto !important;
}

/* WebKit (Chrome, Safari) */
.scrollable_container::-webkit-scrollbar {
    width: 8px !important;
}

.scrollable_container::-webkit-scrollbar-thumb {
    background-color: #33d47c !important;
}

.scrollable_container::-webkit-scrollbar-thumb:hover {
    background-color: #11b45a !important;
}

.scrollable_container:active::-webkit-scrollbar-thumb {
    background-color: #008d3f !important;
}

.scrollable_container::-webkit-scrollbar-track {
    background-color: #c2c2c2a6 !important;
}

/* Firefox */
.scrollable_container {
    scrollbar-width: thin !important;
    scrollbar-color: #33d47c #c2c2c2a6 !important;
}

.modal-collection .modal-dialog {
    position: fixed;
    bottom: -50px;
    left: 0;
    right: 0;
    margin: 0;
}

.modal-collection .modal-content {
    height: 70vh;
    border-radius: 0;
}

.skel-dev {
    position: relative;
}

.message {
    background-color: #3b42538f;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    flex-direction: column;
    width: 100%;
}

.message p {
    font-size: 22px;
    margin-top: 10px;
}

.img-score {
    width: 25px;
    height: 25px;
}

.blured-div {
    position: absolute;
    background-color: black;
    opacity: 0.8;
    width: 100% !important;
    height: 100% !important;
    z-index: 100;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    align-items: center;
    justify-content: center;
}
</style>
